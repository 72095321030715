/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  Reducer,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import { Button } from '@components/Button';
import { useAvailableSponsorsQueryLazy } from '@components/Forms/Select/SponsorSelect/hooks';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import PageSteps from '@components/PageSteps';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import {
  // useCreateIndividual,
  useCreateMembership,
  useFetchMemberDetails,
  // useUpdateIndividual,
} from '@repositories/clubs';

import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import {
  IdentificationParameters,
  mapDataToPayloadCreateIndv,
  mapMemberValuesToFormInput,
  mapPayloadToData,
  MemberDetailsInput,
  MemberDetailsOutput,
  MembershipData,
} from '@domui-domain/clubs';
import { DynamicData } from '@domui-domain/type';
import { useAddMemberToClub } from '@domui-hooks/useAddMemberToClub';
import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import { useFetchIndividual } from '@domui-hooks/useFetchIndividual';
import { useUpdateIndividual } from '@domui-hooks/useUpdateIndividual';
import IdentifyMember from '@domui-presenters/web/components/MembershipManagement/IdentifyMember';
import MemberDetails from '@domui-presenters/web/components/MembershipManagement/MemberDetails';
import MembershipInformation from '@domui-presenters/web/components/MembershipManagement/MembershipInformation';
import { getClubMembersPath } from '@domui-use-cases/clubs';
import { useCreateIndividual } from 'src/domui/hooks/useCreateIndividual';

import { useTranslation } from '@external/react-i18next';
import { useFetchRiIndividualId } from '@hooks/useFetchRiIndividualId';

import { UpdateIndividualMutationVariables } from '@typings/operations';

interface ClubAddMemberPageProps extends RouteComponentProps {
  clubId: string;
  isRotaractClub: boolean;
  clubName: string;
  riClubId: string;
}
export const hasChanges = (obj1: DynamicData, obj2: DynamicData): boolean => {
  const isDifferent = (val1: DynamicData, val2: DynamicData) => {
    if (
      typeof val1 === 'object' &&
      typeof val2 === 'object' &&
      val1 !== null &&
      val2 !== null
    ) {
      return hasChanges(val1, val2); // Recursive comparison for nested objects
    }
    return val1 !== val2; // Primitive value comparison
  };
  if (!obj1 || !obj2) {
    throw new Error('Both objects must be defined');
  }
  return (
    Object.keys(obj1).some(
      key => key in obj2 && isDifferent(obj1?.[key], obj2?.[key])
    ) ||
    Object.keys(obj2).some(
      key => key in obj1 && isDifferent(obj1?.[key], obj2?.[key])
    )
  );
};
const AddMember: React.FC<ClubAddMemberPageProps> = ({
  clubId: id,
  isRotaractClub,
  clubName,
  riClubId,
}) => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const addSuccessRef = useRef(addSuccess);

  enum AddMemberSteps {
    IDENTIFY,
    INFO,
    MEMBERSHIP,
  }

  const [step, setStep] = useState<AddMemberSteps>(AddMemberSteps.IDENTIFY);
  const isFirstStep = step === AddMemberSteps.IDENTIFY;

  const [filters, setFilters] = useReducer<
    Reducer<IdentificationParameters, IdentificationParameters>
  >((state, action) => {
    return { ...state, ...action };
  }, {});

  // Initiate a state for the current member id.
  // - undefined: no member was selected and the user did not choose to create one
  // - false: no member was selected, the user decided to create a new one
  // - string: the ID of the selected member
  const [selectedMember, selectMember] = useState<undefined | string | false>(
    undefined
  );

  // When a member has been selected, attempt to load data from the api.
  const { data, loading, error, fetchIndividual } = useFetchIndividual();

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.
  const individual = data;

  const initialData = mapMemberValuesToFormInput(
    individual,
    filters.firstName,
    filters.lastName,
    filters.email
  );
  // Member data that has been reviewed or created by the user.
  const [memberDataReviewed, setMemberDataReviewed] = useState<
    MemberDetailsInput | undefined | null
  >();
  // If we have reviewed member data, massage it into a mutation payload.
  const [memberDataPayload, setMemberDataPayload] = useState<
    MemberDetailsOutput | undefined
  >();
  const [initialValues, setInitialValues] = useState({});
  const [memberId, setMemberId] = useState<undefined | string>();
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const handleBackClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(getClubMembersPath(id));
    } else {
      setStep(step - 1);
    }
  };

  const {
    data: addMemberData,
    loading: addMemberLoading,
    error: addMemberError,
    addMemberToClub,
  } = useAddMemberToClub();

  const {
    data: fetchRiIndividualIdData,
    loading: updateLoading,
    error: updateError,
    updateIndividual,
  } = useUpdateIndividual();

  const {
    data: createIndividualData,
    loading: createIndividualLoading,
    error: createIndividualError,
    createIndividual,
  } = useCreateIndividual();
  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  // const [
  //   createIndividual,
  //   {
  //     error: createIndividualError,
  //     called: createIndividualCalled,
  //     loading: createIndividualLoading,
  //   },
  // ] = useCreateIndividual();

  const [fetchSponsorsLazily] = useAvailableSponsorsQueryLazy();

  useEffect(() => {
    if (fetchRiIndividualIdData) {
      setMemberId(String(fetchRiIndividualIdData?.riIndividualId));
    }
  }, [fetchRiIndividualIdData]);
  useEffect(() => {
    fetchIndividual(selectedMember || '');
  }, [selectedMember]);

  const {
    data: countriesRegions,
    //  loading
    error: errorCountries,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  useEffect(() => {
    if (
      addMemberData &&
      !addMemberError
      // memberId &&
      // ((createIndividualCalled && !createIndividualError) ||
      //   (updateIndividualCalled && !updateIndividualError))
    ) {
      const localizedName = memberDataReviewed?.localizedName;
      if (memberId) {
        addSuccessRef.current(
          t(
            'add_member.success',
            'Successfully added {{name}} to club with Member ID {{memberId}}.',
            {
              name: localizedName,
              memberId,
            }
          ),
          { id: 'form.success' }
        );
      } else {
        addSuccessRef.current(
          t('add_member.success', 'Successfully added {{name}} to club ', {
            name: localizedName,
            memberId,
          }),
          { id: 'form.success' }
        );
      }

      getChannel('add-member').postMessage(
        t(
          'club-addMember-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );
      setGlobal(false);
      localizedNavigate(`/domui/club/${id}/roster`);
    }
  }, [
    addMemberData,
    // addMemberError,
    // memberDataReviewed,
    addSuccessRef,
    // id,
    // createIndividualCalled,
    // createIndividualError,
    // updateIndividualCalled,
    // updateIndividualError,
    memberId,
  ]);

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isFirstStep) {
      localizedNavigate(getClubMembersPath(id));
    } else {
      setStep(step - 1);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      show(true);
    } else {
      localizedNavigate(getClubMembersPath(id));
    }
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (
    loading ||
    addMemberLoading ||
    updateLoading ||
    createIndividualLoading
    // updateIndividualLoading ||
    // createMembershipLoading ||
    // fetchRiIndividualLoading
  ) {
    return <Loading />;
  }

  const noPaddingClass = 'p-0';
  const navClassName = 'text-small font-bold mt-4 mb-0';

  const handleSubmit = async (membershipData: Partial<MembershipData>) => {
    let individualId = '';

    const createMembershipPayload = {
      clubId: `${id}`,
      admissionDate:
        membershipData.admissionDate || moment().format('YYYY-MM-DD'),
      individualId,
      sponsorIds: membershipData.sponsoredBy,
      memberType:
        `${
          membershipData?.memberType === 'Honorary'
            ? `${membershipData?.memberType} Member`
            : 'Member'
        } ` || 'Member',
    };

    if (memberDataReviewed && membershipData) {
      if (!selectedMember) {
        const payload = mapDataToPayloadCreateIndv(
          memberDataPayload,
          individual,
          countriesRegions
        );
        const individualData = await createIndividual(payload);
        if (individualData) {
          individualId = String(individualData?.id);
          createMembershipPayload.individualId = individualId;
          await addMemberToClub(createMembershipPayload);
          setMemberId(individualData?.riIndividualId);
        }

        // const result = await createIndividual({
        //   variables: memberDataPayload,
        // });
        // individualId = String(result.data?.createIndividual.individualId);
        // await createMembership({
        //   variables: { ...createMembershipVariables, individualId },
        // });
      } else {
        const canUpdateOrNot = hasChanges(memberDataPayload, initialValues);
        if (canUpdateOrNot) {
          const payload = mapPayloadToData(
            memberDataPayload,
            selectedMember,
            individual,
            countriesRegions
          );

          individualId = selectedMember;
          await updateIndividual(individualId, payload);
        }
        createMembershipPayload.individualId = individualId;

        await addMemberToClub(createMembershipPayload);
      }

      // await fetchRiIndividualId({
      //   variables: {
      //     individualId,
      //     delayAfterResponse: 5000,
      //   },
      // });

      //   fetchSponsorsLazily({
      //     variables: {
      //       clubId: riClubId || '',
      //       name: '',
      //       admissionDate:
      //         membershipData.admissionDate || moment().format('YYYY-MM-DD'),
      //     },
      //   });
      // }
    }
  };

  switch (step) {
    case AddMemberSteps.IDENTIFY:
      return (
        <PageSteps
          step={1}
          total={3}
          className={noPaddingClass}
          navClassName={navClassName}
          backHandler={handleBackClick}
        >
          <IdentifyMember
            submitHandler={(id: string | false | undefined) => {
              setMemberDataReviewed(null);
              selectMember(id);
              if (id !== undefined) {
                setStep(AddMemberSteps.INFO);
              }
            }}
            filters={filters}
            setFilters={setFilters}
            isRotaractClub={isRotaractClub}
            clubName={clubName}
            handleFormFieldsTouched={handleFormFieldsTouched}
          >
            <BackButton />
          </IdentifyMember>
        </PageSteps>
      );
    case AddMemberSteps.INFO:
      return (
        <PageSteps
          step={2}
          total={3}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <MemberDetails
            data={memberDataReviewed || initialData}
            id={id}
            initialData={initialData}
            submitHandler={(
              formData: MemberDetailsInput,
              dataToUpdate: MemberDetailsOutput,
              initialValues: MemberDetailsInput
            ) => {
              setStep(AddMemberSteps.MEMBERSHIP);
              setMemberDataReviewed(formData);
              setMemberDataPayload(dataToUpdate);
              setInitialValues(initialValues);
            }}
            handleFormFieldsTouched={handleFormFieldsTouched}
          >
            <BackButton />
          </MemberDetails>
        </PageSteps>
      );
    case AddMemberSteps.MEMBERSHIP:
      return (
        <PageSteps
          step={3}
          total={3}
          backHandler={handleBackClick}
          className={noPaddingClass}
          navClassName={navClassName}
        >
          <MembershipInformation
            isRotaractClub={isRotaractClub}
            // hasActiveMembership={
            //   memberDataReviewed
            //     ? memberDataReviewed.hasActiveMembership
            //     : false
            // }
            clubId={id || ''}
            recentMemberships={memberDataReviewed?.recentMemberships || []}
            submitHandler={handleSubmit}
            handleFormFieldsTouched={handleFormFieldsTouched}
            individualId={selectedMember}
          >
            <BackButton />
          </MembershipInformation>
        </PageSteps>
      );
    default:
      return null;
  }
};

export default AddMember;
