import React from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import { ContextMenu } from '@components/ContextMenu';
import Icon from '@components/Icon';
import InfoMessage from '@presenters/web/components/InfoMessage';

import getOfficerTermLabel from '@use-cases/clubs/helpers/getOfficerTermLabel';

import { isToday } from '@utils/datetime';

import { ROTARY_ASSISTANCE_EMAIL } from '@constants/index';
import MemberRow from '@domui-components/MemberRow';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';
import { getOfficersPagePath } from '@domui-use-cases/clubs';
import { getClubRoleTValue } from '@domui-utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

interface ClubOfficerRoleItemProps extends RouteComponentProps {
  role: Role;
  clubId: string;
  handleRoleSelect(
    startDate: string,
    endDate: string,
    role?: string,
    roleId?: string
  ): void;
  termStartYear: string;
}
type Role = {
  id: string;
  value: string;
  mandatory: boolean;
  officers: Officer[];
};
type Officer = {
  id: string;
  startDate: string;
  endDate: string;
  individual: Individual;
};
type Individual = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  prefix: string;
  suffix: string;
  localizedName: string;
  email: string;
  photoUri: string;
  _links: null;
};
const ClubOfficerRoleItem: React.FC<ClubOfficerRoleItemProps> = ({
  clubId,
  role,
  handleRoleSelect,
  termStartYear,
}) => {
  const { t } = useTranslation();
  const { officers, value, mandatory } = role;

  const handleAssign = (officer: Officer) => () => {
    if (officer.startDate && officer.endDate) {
      handleRoleSelect(officer.startDate, officer.endDate, role.value, role.id);
    }
  };

  const getContextMenuItems = (officer: Officer, individual: Individual) => {
    const contextMenuItems = [];

    const isDisabled = isToday(officer?.startDate);

    // only removable if currently in office
    if (moment(officer?.endDate).isAfter()) {
      contextMenuItems.push({
        id: 1,
        label: `${t(
          'officers-result.context-menu.remove-from-office',
          'Remove from office'
        )}<span class="sr-only"> (${getFullName(individual)})</span>`,
        path: `${getOfficersPagePath(clubId)}/${
          officer?.id
        }/${termStartYear}/remove`,
      });
      contextMenuItems.push({
        id: 2,
        label: `${t(
          'officers-result.context-menu.replace-from-office',
          'Replace from office'
        )}`,
        path: `${getOfficersPagePath(clubId)}/replace-member/${
          individual?.id
        }/${officer?.id}/${role?.id}/replace/${officer?.startDate}/${
          officer?.endDate
        }`,
        isDisabled,
      });
    }

    return contextMenuItems;
  };

  const roleTitle = (
    <div className="role-title">
      <div className="h5 alternate">
        {getClubRoleTValue(t, value)}
        {mandatory ? '*' : ''}
      </div>
    </div>
  );

  return (
    <>
      {officers.map((officer, index: number) => {
        const individual = officer?.individual;
        const { id, photoUri } = individual || {};
        const isSingleOfficer = officers.length < 2;
        const individualName = getFullName(individual);
        const isAssigned = !!individualName;
        const isLastIndex = officers.length - 1 === index;
        const unavailableTerm = Boolean(
          !isAssigned && !isLastIndex && !isSingleOfficer
        );
        const contextMenuItems = getContextMenuItems(officer, individual);
        const isContextMenuItemsExist = !!contextMenuItems.length;
        if (unavailableTerm) {
          return (
            <MemberRow
              id=""
              unassigned
              key={`unavailableTerm-${value}-${index.toString()}`}
              roleTitle={roleTitle}
              terms={
                <div className="text-xs text-gray-600">
                  {getOfficerTermLabel(t, officer.startDate, officer.endDate)}
                </div>
              }
              infoMessage={
                <InfoMessage
                  message={t(
                    'club-officers.sorry-cannot-manage-officer',
                    `Sorry, you can't add an officer for a period that has passed. Contact <a href='mailto:{{email}}'>{{email}}</a> for assistance.`,
                    { email: ROTARY_ASSISTANCE_EMAIL }
                  )}
                />
              }
            />
          );
        }

        if (!isAssigned) {
          return (
            <MemberRow
              id=""
              unassigned
              key={`assignedRole-${value}-${index.toString()}`}
              unAssignMessage={
                <div className="text-lg leading-lg-heading font-normal text-bright-blue-600">
                  {t('officer-result.unassigned', 'Unassigned')}
                </div>
              }
              roleTitle={roleTitle}
              actionButton={
                <button
                  type="button"
                  onClick={handleAssign(officer)}
                  className="focus:shadow-none desktop:mr-3"
                  data-testid="assign-button"
                >
                  <span aria-hidden="true" className="text-center">
                    <Icon
                      className="w-8 h-8 tablet:w-10 tablet:h-10"
                      name="circles-lg/add"
                    />
                  </span>
                </button>
              }
            />
          );
        }

        return (
          <MemberRow
            id={id || index.toString()}
            key={`${value}-${id}-${index.toString()}`}
            fullName={individualName || undefined}
            photoUri={photoUri || undefined}
            isLinkToProfile={isAssigned}
            roleTitle={roleTitle}
            terms={
              <div className="text-xs text-gray-600">
                {getOfficerTermLabel(t, officer.startDate, officer.endDate)}
              </div>
            }
            profilePreviousLinkPath={getOfficersPagePath(clubId)}
            actionButton={
              isContextMenuItemsExist && (
                <ContextMenu
                  title={individualName || ''}
                  items={contextMenuItems}
                />
              )
            }
            actionButtonMobile={
              isContextMenuItemsExist && (
                <ContextMenu
                  title={individualName || ''}
                  items={contextMenuItems}
                  isRightIndent={false}
                  optionsToggleButton
                />
              )
            }
          />
        );
      })}
    </>
  );
};

export default ClubOfficerRoleItem;
