import React, { createContext, Dispatch, useEffect, useState } from 'react';

import { RouteComponentProps, Router } from '@reach/router';

import Icon from '@components/Icon';
import { OneColumn } from '@components/Layouts/OneColumn';
import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';
import AddClubManagementVendor from '@presenters/web/pages/Clubs/Details/ManagementSystems/AddVendor';
import EditManagementSystems from '@presenters/web/pages/Clubs/Details/ManagementSystems/EditVendor';
import ReplaceRoleToMember from '@presenters/web/pages/Clubs/ManageClubOfficers/ReplaceRoleToMember';
import LeadsLandingPage from '@presenters/web/pages/Leads/MMLPage';
import AddCandidate from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/AddCandidate';
import CandidateDetails from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/CandidateDetails/CandidateDetails';
import CandidateNonClubMember from '@presenters/web/pages/Leads/MMLPage/CandidateManagement/CandidateDetails/CandidateNonClubMember';
import IdentifyClubCandidate from '@presenters/web/pages/Leads/MMLPage/IdentifyClubCandidate';

import { ClubLeadersContextType } from '@domain/clubs/types';

import { isManagerLevelLead, isViewLevel } from '@utils/access-helpers';
import { FEATURE_LEADS_MML, isEnabled } from '@utils/features';
import { getOrganizationBase } from '@utils/getOrganizationBase';

import AuthenticatedRoute from '@domui-components/Auth/AuthenticatedRoute';
import { useAppConfig } from '@domui-hooks/appConfig';
import { useFetchAdminAccessRights } from '@domui-hooks/iam/useFetchAdminAccessRights';
import { useDominoUserAccount } from '@domui-hooks/useDominoUserAccount';
import ClubDetails from '@domui-presenters/web/pages/Clubs/Details';
import EditClubContact from '@domui-presenters/web/pages/Clubs/Details/Contacts/EditContact';
import AddMeeting from '@domui-presenters/web/pages/Clubs/Details/Meetings/AddMeeting';
import EditMeeting from '@domui-presenters/web/pages/Clubs/Details/Meetings/EditMeeting';
import ClubFinance from '@domui-presenters/web/pages/Clubs/Finance/ClubFinance';
import AssignMemberToRole from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/AssignMemberToRole';
import AssignRoleToMember from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/AssignRoleToMember';
import RemoveOfficer from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/RemoveOfficer';
import ReplaceMemberToRole from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/ReplaceMemberToRole';
import ClubMembers from '@domui-presenters/web/pages/Clubs/Members';
import AddMember from '@domui-presenters/web/pages/Clubs/MembershipManagement/AddMember/AddMember';
import ChangeMembership from '@domui-presenters/web/pages/Clubs/MembershipManagement/ChangeMembership';
import EditMembership from '@domui-presenters/web/pages/Clubs/MembershipManagement/EditMembership';
import TerminateMembership from '@domui-presenters/web/pages/Clubs/MembershipManagement/TerminateMembership';
import ClubOfficers from '@domui-presenters/web/pages/Clubs/Officers/ClubOfficers';
import {
  getClubIdFromPath,
  getClubSubMenuItems,
  isClubRotaract,
} from '@domui-use-cases/clubs';
import { isClubRotaractSatellite } from '@domui-use-cases/clubs/helpers/isClubRotaractSatellite';
import { getClubTypeName } from '@domui-utils/getClubTypeName';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

const defaultClubLeadersContext = [] as ClubLeadersContextType;

export const ClubLeadersContext = createContext<
  [
    ClubLeadersContextType,
    Dispatch<React.SetStateAction<ClubLeadersContextType>>
  ]
>([
  defaultClubLeadersContext,
  prevState => ({ ...prevState, defaultClubLeadersContext }),
]);

const Club: React.FC<RouteComponentProps & {
  pageContext: { languagePrefix: string };
}> = ({ pageContext: { languagePrefix }, location }) => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const individualIdForFetch = (user?.isLoggedIn && user.individualId) || null;
  const { data: userData } = useDominoUserAccount(individualIdForFetch || '');

  const clubId = getClubIdFromPath(location?.pathname);
  const { data, loading, fetchAdminAccessRights } = useFetchAdminAccessRights();

  useEffect(() => {
    if (clubId) {
      fetchAdminAccessRights(clubId, userData, user?.individualId ?? undefined);
    }
  }, [clubId, userData, user]);

  const [clubLeadersContext, setClubLeadersContext] = useState<
    ClubLeadersContextType
  >({
    ...defaultClubLeadersContext,
  });

  if (!data || loading) {
    return <Loading />;
  }
  const { clubInfo, operationsAccess } = data || {};

  const {
    Name: clubName = '',
    Type: clubType = '',
    organizationBase = null,
    riClubId = '',
  } = clubInfo || {};

  if (!operationsAccess?.clubId) {
    return <NotFound default />;
  }

  const hasPermissonsToFinancesGoalsReports = isViewLevel(
    operationsAccess.financesGoalsReports
  );

  const clubSubMenu = getClubSubMenuItems(
    t,
    operationsAccess,
    clubType,
    clubId
  );

  const isRotaractSatellite = isClubRotaractSatellite(clubType);
  const isRotaractClub = isClubRotaract(clubType);

  const isLeaderForCurrentClub = isManagerLevelLead(
    operationsAccess.memberships
  );

  const isLeader = operationsAccess.leaderships === 'Manager';

  const isRotaractClubType = isRotaractClub || isRotaractSatellite;
  const isSearchPageComeFrom = sessionStorage.getItem('search') || '';

  const isMembershipCandidatesFeature = isEnabled(FEATURE_LEADS_MML);

  const isAuthenticatedToLeadsDrupal = false;
  return (
    <OneColumn className="mb-20">
      <Helmet
        titleTemplate={`${t('metadata.title.clubs', 'Clubs')}: %s | ${t(
          'metadata.title.default',
          'My Rotary'
        )}`}
      />
      <div>
        {window.history.length > 1 && isSearchPageComeFrom && (
          <div>
            <span
              onClick={() => window.history.back()}
              className="inline-flex items-center font-bold text-bright-blue-600 link-styles text-xs leading-xs-heading mb-8"
            >
              <Icon name="arrows/left" className="mr-2" size="12" />
              {t('back-link-text', 'Back')}
            </span>
          </div>
        )}

        <div className="mb-5 tablet:mb-15 desktop:mb-15">
          <Title>{clubName}</Title>
          <h3>
            {getClubTypeName(t, clubType)}
            {organizationBase &&
              ` (${getOrganizationBase(t, organizationBase)})`}
          </h3>
        </div>
      </div>
      <ClubLeadersContext.Provider
        value={
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          [clubLeadersContext, setClubLeadersContext]
        }
      >
        <Submenu items={clubSubMenu} location={location} />
        <Router basepath={`/${languagePrefix}domui/club`}>
          <ClubDetails
            path="/:clubId/details"
            clubId={clubId}
            domOrgId={clubId}
          />

          {/* Hide all routes except Club Details for Rotaract and RotaractSatellite */}

          {!isRotaractSatellite && (
            <>
              <AuthenticatedRoute
                isRotaractClub={isRotaractClub}
                path="/:clubId/roster"
                operationsAccess={operationsAccess}
                Component={ClubMembers}
              />

              <AuthenticatedRoute
                path="/:clubId/add-member"
                isRotaractClub={isRotaractClub}
                clubName={clubName}
                Component={AddMember}
                riClubId={riClubId}
              />
            </>
          )}
          {/* {(isRotaractClub || isRotaryClub(clubType)) &&
            isEditLevel(operationsAccess?.leaderships) && ( */}
          <>
            <AuthenticatedRoute
              path="/:clubId/officers"
              clubType={clubType}
              Component={ClubOfficers}
            />
            <AuthenticatedRoute
              path="/:clubId/edit-meeting/:meetingIndex"
              Component={EditMeeting}
              dominoId={clubId}
            />
            <AuthenticatedRoute
              path="/:clubId/add-meeting"
              Component={AddMeeting}
              dominoId={clubId}
            />
            <AuthenticatedRoute
              path="/:clubId/terminate-membership/:individualId"
              Component={TerminateMembership}
            />
            <AuthenticatedRoute
              path="/:clubId/edit-contact"
              Component={EditClubContact}
              domOrgId={clubId}
              clubId={clubId}
            />
            <AuthenticatedRoute
              path="/:clubId/officers/:leadershipId/:termStartYear/remove"
              Component={RemoveOfficer}
            />
            <AuthenticatedRoute
              path="/:clubId/officers/assign-member/:roleId/:startDate/:endDate"
              clubType={clubType}
              Component={AssignMemberToRole}
            />
            <AuthenticatedRoute
              path="/:clubId/edit-membership/:individualId"
              Component={EditMembership}
            />
            <AuthenticatedRoute
              path="/:clubId/change-membership/:individualId"
              Component={ChangeMembership}
            />
            <AuthenticatedRoute
              path="/:clubId/officers/assign-role/:individualId"
              Component={AssignRoleToMember}
            />
            <AuthenticatedRoute
              path="/:clubId/officers/replace-role/:individualId"
              Component={ReplaceRoleToMember}
            />
            <AuthenticatedRoute
              path="/:clubId/officers/replace-member/:individualId/:leadershipId/:roleId/replace/:startDate/:endDate"
              Component={ReplaceMemberToRole}
              clubType={clubType}
            />
            <AuthenticatedRoute
              path="/:clubId/edit-management-systems"
              Component={EditManagementSystems}
            />
            <AuthenticatedRoute
              path="/:clubId/add-vendor"
              Component={AddClubManagementVendor}
            />
          </>
          {/* // )} */}
          {hasPermissonsToFinancesGoalsReports && (
            <AuthenticatedRoute
              path="/:clubId/finances"
              orgType={clubType}
              isLeader={isLeader}
              Component={ClubFinance}
            />
          )}

          {isMembershipCandidatesFeature &&
            (isAuthenticatedToLeadsDrupal || isLeaderForCurrentClub) &&
            !isRotaractSatellite && (
              <>
                <AuthenticatedRoute
                  path="/:clubId/manage-membership"
                  type="Club"
                  isDistrict={false}
                  Component={LeadsLandingPage}
                />
                <AuthenticatedRoute
                  path="/:clubId/manage-membership/candidate-details"
                  clubId={clubId}
                  Component={CandidateDetails}
                />
                <AuthenticatedRoute
                  isRotaractClub={isRotaractClub}
                  path="/:clubId/manage-membership/convert-candidate/:individualId"
                  clubId={clubId}
                  Component={CandidateNonClubMember}
                  clubName={clubName}
                  riClubId={riClubId}
                />
                <AuthenticatedRoute
                  path="/:clubId/manage-membership/candidate-details/identify-candidate"
                  isRotaractClub={isRotaractClub}
                  clubId={clubId}
                  Component={IdentifyClubCandidate}
                />
                <AuthenticatedRoute
                  path="/:clubId/manage-membership/add-member"
                  isRotaractClub={isRotaractClub}
                  clubName={clubName}
                  Component={AddCandidate}
                />
              </>
            )}

          {hasPermissonsToFinancesGoalsReports && (
            <>
              <AuthenticatedRoute
                path="/:clubId/goals"
                // Component={ClubGoals}
                Component={() => <div>Club Goal Details</div>}
              />
              {!isRotaractClubType && (
                <AuthenticatedRoute
                  path="/:clubId/reports"
                  // Component={ClubReports}
                  Component={() => <div>Club Reports</div>}
                  // adminAccessRightsData={{ data, loading }}
                  adminAccessRightsData={{
                    data: {},
                    loading: false,
                  }}
                />
              )}
            </>
          )}
          <NotFound default />
        </Router>
      </ClubLeadersContext.Provider>
    </OneColumn>
  );
};

export default Club;
