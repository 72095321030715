import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';

import { CREATE_CLUB_MEETING_INITIAL_VALUES } from '@domain/clubs/constants';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { mapMeetingInputToValues } from '@domui-domain/clubs';
import { DynamicData } from '@domui-domain/type';
import { useAddMeeting } from '@domui-hooks/useAddMeeting';
import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import MeetingForm from '@domui-presenters/web/components/ClubsMeetingForm';
import { MeetingFormValues } from '@domui-presenters/web/components/ClubsMeetingForm/types';
import { getBackButtonLabel, getClubDetailsPath } from '@domui-use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
}

const AddMeeting: React.FC<Props> = ({ clubId }) => {
  const { t, i18n } = useTranslation();
  const { addError } = useNotifications();
  const [meetingInfo, setMeetingInfo] = useState<DynamicData>(null);

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const clubDetailsPath = getClubDetailsPath(clubId);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const { data: countries, fetchCountries } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  const {
    loading: updatedMeetingLoading,
    error: updatedMeetingError,
    addMeeting,
  } = useAddMeeting();

  useErrorHandling(
    updatedMeetingError?.description
      ? updatedMeetingError.description
      : t('add-meeting.error', 'An error occurred.'),
    !!updatedMeetingError,
    'add-meeting.error'
  );

  useEffect(() => {
    if (meetingInfo) {
      addMeeting(clubId, meetingInfo, clubDetailsPath);
    }
  }, [meetingInfo]);

  if (updatedMeetingLoading) {
    return <Loading />;
  }

  const handleSubmit = async (values: MeetingFormValues) => {
    try {
      const selectCountry: DynamicData = countries?.find(
        (c: DynamicData) => c?.id === values.countryId
      );
      const mutValues: DynamicData = {
        meeting: {
          ...mapMeetingInputToValues(i18n.language, selectCountry, values),
          meetingId: null,
        },
      };
      setMeetingInfo(mutValues);
    } catch (error) {
      addError((error as Error).message);
    }
  };

  const modalOnConfirmHandler = () => {
    localizedNavigate(clubDetailsPath);
  };

  return (
    <div className="mt-4">
      <LinkPrevious
        path=""
        showModal={modalBackHandler}
        label={getBackButtonLabel(t)}
      />
      <Title>{t('club-addmeeting.title', 'Add New Meeting')}</Title>
      <MeetingForm
        initialValues={
          { ...CREATE_CLUB_MEETING_INITIAL_VALUES } as MeetingFormValues
        }
        countries={countries}
        handleSubmit={handleSubmit}
        onCancelClick={modalBackHandler}
      />
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={globalHide}
        onConfirm={modalOnConfirmHandler}
      />
    </div>
  );
};

export default AddMeeting;
