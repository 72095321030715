import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';

import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import Title from '@components/Title';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import {
  mapMeetingInputToValues,
  mapMeetingValuesToFormInput,
} from '@domui-domain/clubs';
import { DynamicData } from '@domui-domain/type';
import { useFetchCountries } from '@domui-hooks/useFetchCountries';
import { useFetchOrganization } from '@domui-hooks/useFetchOrganization';
import { useUpdateMeeting } from '@domui-hooks/useUpdateMeeting';
import MeetingForm from '@domui-presenters/web/components/ClubsMeetingForm';
import { MeetingFormValues } from '@domui-presenters/web/components/ClubsMeetingForm/types';
import { getBackButtonLabel, getClubDetailsPath } from '@domui-use-cases/clubs';

import { useTranslation } from '@external/react-i18next';

interface Props extends RouteComponentProps {
  clubId: string;
  meetingIndex?: string;
}
const EditMeeting: React.FC<Props> = ({ clubId, meetingIndex }) => {
  const { t, i18n } = useTranslation();
  const { addError } = useNotifications();
  const [meetingInfo, setMeetingInfo] = useState<DynamicData>(null);

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const clubDetailsPath = getClubDetailsPath(clubId);

  const {
    loading,
    data: getOrganizationData,
    fetchOrganization,
  } = useFetchOrganization();

  useEffect(() => {
    if (clubId) {
      fetchOrganization(clubId);
    }
  }, [clubId]);

  const { data: countries, fetchCountries } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);
  const {
    error: updatedMeetingError,
    loading: isUpdating,
    updateMeeting,
  } = useUpdateMeeting();

  useErrorHandling(
    updatedMeetingError?.description
      ? updatedMeetingError.description
      : t('add-meeting.error', 'An error occurred.'),
    !!updatedMeetingError,
    'add-meeting.error'
  );

  useEffect(() => {
    if (meetingInfo) {
      updateMeeting(clubId, meetingInfo, clubDetailsPath);
    }
  }, [meetingInfo]);

  if (loading || isUpdating) {
    return <Loading />;
  }

  const meetings = getOrganizationData?.meetings || {};

  const meeting = meetings?.[Number(meetingIndex)];

  const handleSubmit = async (values: MeetingFormValues) => {
    try {
      const selectCountry: DynamicData = countries?.find(
        (c: DynamicData) => c?.id === values.countryId
      );
      const mutValues: DynamicData = {
        meetingId: meeting.meetingId,
        meeting: {
          ...mapMeetingInputToValues(i18n.language, selectCountry, values),
        },
      };
      setMeetingInfo(mutValues);
    } catch (error) {
      addError((error as Error).message);
    }
  };

  const modalOnConfirmHandler = () => {
    localizedNavigate(clubDetailsPath);
  };

  return (
    <div className="mt-4">
      {meeting && (
        <>
          <LinkPrevious
            path=""
            showModal={modalBackHandler}
            label={getBackButtonLabel(t)}
          />
          <Title>{t('club-editmeeting.title', 'Edit Meeting')}</Title>
          <MeetingForm
            initialValues={mapMeetingValuesToFormInput(meeting)}
            countries={countries}
            handleSubmit={handleSubmit}
            onCancelClick={modalBackHandler}
          />
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      )}
    </div>
  );
};

export default EditMeeting;
