import React from 'react';

import { camelCase } from 'lodash';

import MemberPreview from '@components/MemberPreview';
import BlockWrapper from '@presenters/web/pages/Districts/Details/BlockWrapper';

// import { getClubRoleTValue } from '@domui-utils/getClubRoleTValue';

import { useTranslation } from '@external/react-i18next';

// import { ClubLeadershipItem, Role } from '@typings/graphql';

type ClubLeadershipItem = {
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  prefix: string;
  suffix: string;
  localizedName: string;
  email?: string | null;
  photoUri?: string | null;
};

type Role = {
  id: string;
  roleId: string;
  role: string;
  startDate: string;
  endDate: string;
  isDelegated: boolean;
  individual: ClubLeadershipItem;
};

type ClubDetailOfficer = Role & {
  individual: ClubLeadershipItem;
};

interface Props {
  leaders: Array<ClubDetailOfficer>;
  isEditable: boolean;
  handleGoToOfficers?: () => void;
  profilePreviousLinkPath?: string;
}
const ClubOfficers: React.FC<Props> = ({
  isEditable,
  leaders,
  handleGoToOfficers,
  profilePreviousLinkPath,
}) => {
  const { t } = useTranslation();

  return (
    <BlockWrapper
      title={t('club-details.club-officers.title', 'Club Officers')}
      titleWithArrow={isEditable}
      handleClick={isEditable ? handleGoToOfficers : undefined}
    >
      {leaders.map(({ individual, role }) => (
        <div key={`leaders-${camelCase(role)}`}>
          <div className="text-2xs text-gray-600 font-bold mb-2">{role}</div>
          <MemberPreview
            name={
              `${individual?.firstName || ''} ${individual?.middleName ||
                ''} ${individual?.lastName || ''}` || ''
            }
            path={`/domui/profile/${individual?.id}`}
            photoUri={individual?.photoUri || ''}
            email={individual?.email || ''}
            noLinkStyles
            linkClassName="underline text-xs text-gray-600 font-bold block"
            key={`members-${individual.id}`}
            dataTestId="officer-item"
            profilePreviousLinkPath={profilePreviousLinkPath}
          />
        </div>
      ))}
    </BlockWrapper>
  );
};

export default ClubOfficers;
