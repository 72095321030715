import React, { useState } from 'react';

import { useField, useFormikContext } from 'formik';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import TextField from '@components/Formik/TextField';
import { WizardPage } from '@components/Formik/Wizard';
import LabelWithValue from '@components/LabelWithValue';
import Title from '@components/Title';
import EmailInfoMessage from '@presenters/web/components/EmailInfoMessage/EmailInfoMessage';

import ConfirnOfficerTerm from './ConfirnOfficerTerm';

import { useTouchedFormFields } from '@use-cases/clubs';
import { useErrorHandling } from '@use-cases/notifications';

import {
  getFormattedDate,
  getFormattedRYRange,
  getRotaryYear,
  isFutureRY,
} from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import {
  ConfirmOfficerValues,
  Entity,
  Individual,
  Period,
  UpdateIndividual,
} from '@domui-domain/clubs/types';

import { useTranslation } from '@external/react-i18next';

const ConfirmOfficer: WizardPage<ConfirmOfficerValues> = ({
  proceed,
  handleFormFieldsTouched,
  step,
  setStep,
  handleCancelBtnClick,
  confirmationModal,
}) => {
  const { t } = useTranslation();

  const { isSubmitting, isValid } = useFormikContext<ConfirmOfficerValues>();
  const [individual] = useField<Individual>('individual');
  const [editIndividual] = useField<UpdateIndividual>('editIndividual');
  const [role] = useField<Entity>('role');
  const [email] = useField<string>('email');
  const [slot] = useField<Period>('slot');
  const [, , customSlotHelpers] = useField<Period | null>('customSlot');

  const [customStart] = useField<Date | null>('customStart');
  const [customEnd] = useField<Date | null>('customEnd');

  const CALENDAR_DATE_FORMAT = 'dd MMM yyyy';
  const DATE_FORMAT = 'DD MMM YYYY';

  const [withinBoundaries, setWithinBoundaries] = useState<boolean>(true);

  const { checkTouchedFields } = useTouchedFormFields(handleFormFieldsTouched);

  const initialConfirmOfficerValues = {
    email: individual?.value ? individual.value.email || '' : '',
    customStart: null,
    customEnd: null,
  };

  const selectedValues = {
    email: email.value,
    customStart: customStart.value,
    customEnd: customEnd.value,
  };

  const { start: roleStartDate, end: roleEndDate } = slot.value;

  checkTouchedFields(initialConfirmOfficerValues, selectedValues);

  const isEmailFieldDisabled =
    !editIndividual.value &&
    individual.value?.email?.toLowerCase() ===
      individual.value?.onlineId?.toLowerCase();

  const noTermsError = t(
    'club-add-officer.select-role.no-terms-available',
    "The term starts before or ends after the user's membership period."
  );

  useErrorHandling(
    noTermsError,
    !withinBoundaries,
    'club-add-officer.select-role.no-terms-available'
  );

  const formatedRoleStartDate = getFormattedDate(roleStartDate);
  const defaultStartDateForFutureRY = moment(roleStartDate).format(DATE_FORMAT);
  const defaultEndDateForFutureRY = moment(roleEndDate).format(DATE_FORMAT);

  const RY = Number(getRotaryYear(formatedRoleStartDate));
  const {
    startYear: selectedRYStart,
    endYear: selectedRYEnd,
  } = getFormattedRYRange(RY);

  const isFutureRotaryYear = isFutureRY(RY);

  const handleConfirmButtonClick = () => {
    const values = {
      start: !isFutureRotaryYear ? moment(customStart.value) : roleStartDate,
      end: !isFutureRotaryYear ? moment(customEnd.value) : roleEndDate,
    };

    const {
      membershipAdmissionDate,
      membershipTerminationDate,
    } = individual.value ? individual.value : editIndividual.value;

    const startsAfterAdmission =
      !membershipAdmissionDate ||
      values.start.isSameOrAfter(moment(membershipAdmissionDate));

    const endsBeforeTermination =
      !membershipTerminationDate ||
      values.end.isSameOrBefore(moment(membershipTerminationDate));

    if (startsAfterAdmission && endsBeforeTermination) {
      customSlotHelpers.setValue(values);
      proceed();
    } else {
      setWithinBoundaries(false);
      if (window) {
        window.scrollTo(0, 0);
      }
    }
  };

  const todayDate = new Date();

  const customStartDateCalendarValues = {
    name: 'customStart',
    label: t(
      'club-add-officer.confirm.custom-start-date-label',
      'Custom Start Date'
    ),
    minDate: roleStartDate.toDate(),
    maxDate: customEnd.value
      ? moment(customEnd.value)
          .add('-1', 'days')
          .toDate()
      : moment(roleEndDate.toDate())
          .add(-1, 'days')
          .toDate(),
    initialDateValue: roleStartDate.isAfter(todayDate)
      ? roleStartDate.toDate()
      : todayDate,
  };

  const customEndDateCalendarValues = {
    name: 'customEnd',
    label: t(
      'club-add-officer.confirm.custom-end-date-label',
      'Custom End Date'
    ),
    minDate: customStart.value
      ? moment(customStart.value)
          .add('1', 'days')
          .toDate()
      : roleStartDate.toDate(),
    maxDate: roleEndDate.toDate(),
    initialDateValue: roleEndDate.toDate(),
  };

  return (
    <>
      <Title className="h2 mb-5 mt-7">
        {t(
          'club-add-officer.confirm.title',
          'Confirm the role of {{selectedRole}}',
          {
            selectedRole: getClubRoleTValue(t, role.value.name),
          }
        )}
      </Title>

      <div className="max-w-lg">
        <LabelWithValue
          label={t('club-add-officer.confirm.name-label', 'Officer')}
          value={individual.value?.name || editIndividual.value?.name}
        />
        <TextField
          name="email"
          label={t('club-add-officer.confirm.email-label', 'Email')}
          required
          disabled={isEmailFieldDisabled}
        />
        <EmailInfoMessage
          emailMessage={t(
            'club-add-officer.confirm.description',
            "Please make sure the member's email address is up-to-date"
          )}
          notificationMessage={t(
            'club-add-officers.privacy-setting-message',
            `Club officer email address(es) will be shared with other club members regardless of an individual’s privacy settings. By adding a new club officer you acknowledge that they are aware that their personal information will be shared.`
          )}
        />
        <LabelWithValue
          label={t('club-add-officer.confirm.role-label', 'Role')}
          value={role.value.name}
        />
        <LabelWithValue
          label={t('club-add-officer.confirm.term-label', 'Term')}
          value={
            <ConfirnOfficerTerm
              selectedRYStart={selectedRYStart}
              selectedRYEnd={selectedRYEnd}
              isSpanRequired={false}
            />
          }
        />

        <LabelWithValue
          label={t('club-add-officer.confirm.start-date-label', 'Start Date')}
          value={
            <div className="custom-datepicker w-45 relative mb-4">
              {!isFutureRotaryYear ? (
                <Calendar
                  label={customStartDateCalendarValues.label}
                  labelHidden
                  name={customStartDateCalendarValues.name}
                  minDate={customStartDateCalendarValues.minDate}
                  maxDate={customStartDateCalendarValues.maxDate}
                  initialDateValue={
                    customStartDateCalendarValues.initialDateValue
                  }
                  dateFormat={CALENDAR_DATE_FORMAT}
                />
              ) : (
                <p>{defaultStartDateForFutureRY}</p>
              )}
            </div>
          }
        />
        <LabelWithValue
          label={t('club-add-officer.confirm.end-date-label', 'End Date')}
          value={
            <div className="custom-datepicker w-45 relative mb-4">
              {!isFutureRotaryYear ? (
                <Calendar
                  label={customEndDateCalendarValues.label}
                  labelHidden
                  name={customEndDateCalendarValues.name}
                  minDate={customEndDateCalendarValues.minDate}
                  maxDate={customEndDateCalendarValues.maxDate}
                  initialDateValue={
                    customEndDateCalendarValues.initialDateValue
                  }
                  dateFormat={CALENDAR_DATE_FORMAT}
                />
              ) : (
                <p>{defaultEndDateForFutureRY}</p>
              )}
            </div>
          }
        />
        <div className="flex flex-col mt-15">
          <Button
            full
            type="button"
            clickHandler={handleConfirmButtonClick}
            disabled={!isValid || isSubmitting}
          >
            {t('club-add-officer.confirm.submit-label', 'Confirm')}
          </Button>
          <Button clickHandler={handleCancelBtnClick} text className="mt-8">
            {t('club-add-officer.confirm.cancel-button', 'Cancel')}
          </Button>
          {confirmationModal?.(step, setStep)}
        </div>
      </div>
    </>
  );
};

export default ConfirmOfficer;
