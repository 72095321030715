import React, { useEffect } from 'react';

import classNames from 'classnames';

import { getPhoneTypeLabel } from '@use-cases/profile';

import { getCountryCode } from '@utils/getCountryCode';

import { useFetchCountries } from '@domui-hooks/useFetchCountries';

import { useTranslation } from '@external/react-i18next';

import { PhoneNumber, PhoneType } from '@typings/graphql';

interface Props {
  phone: PhoneNumber;
  className?: string;
  phoneNumberColor?: string;
}

export type Country = {
  id: string;
  shortCode: string;
  countryName: string;
  RequireState: boolean;
};

const ContactInformationPhone: React.FC<Props> = ({
  phone,
  className,
  phoneNumberColor,
}) => {
  const { data, fetchCountries } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  const { t } = useTranslation();
  const country = data?.find(
    (country: Country) => country.id === phone.countryId
  );
  const countryPhoneCode = getCountryCode(country?.shortCode);
  const fullPhoneNumber = `00${countryPhoneCode && `${countryPhoneCode}`}${
    phone.number
  }`;
  const formattedPhoneNumber =
    countryPhoneCode && phone.number
      ? `+${countryPhoneCode} ${phone.number}`
      : 'None';
  return (
    <div className={className}>
      <a
        aria-hidden="true"
        className="inline-block tablet:hidden"
        href={`tel:${fullPhoneNumber}`}
      >
        {formattedPhoneNumber}
      </a>
      <span
        className={classNames(
          'hidden tablet:inline-block',
          !phoneNumberColor ? 'text-dark-blue-400' : phoneNumberColor
        )}
      >
        {formattedPhoneNumber}
      </span>
      {phone.extension
        ? `, ${t('contact-information.phone-extension', 'ext')}. ${
            phone.extension
          }`
        : ''}
      {countryPhoneCode && phone.number && (
        <span> ({getPhoneTypeLabel(t, phone.type || PhoneType.Business)})</span>
      )}
    </div>
  );
};

export default ContactInformationPhone;
