import { useState } from 'react';

import { DomuiError } from '@domui-domain/type';
import Organization from '@domui-utils/workflow/organization';

// Custom hook to handle API calls
export const useDeleteClubMeeting = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const deleteClubMeeting = async (dominoId: string) => {
    // Clear error before new request
    try {
      if (!dominoId) {
        throw new Error('Invalid dominoId');
      }

      setLoading(true);
      setError(undefined);
      const wfService = new Organization();
      const response = await wfService.deleteClubMeeting(dominoId);
      setLoading(false);
      return response;
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, deleteClubMeeting };
};
