import { useState } from 'react';

import { DomuiError } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

type terminateClubMembershipArgs = {
  individualId: string;
  clubId: string;
  terminationDate: string;
  terminationReasonId: string;
};
// Custom hook to handle API calls
export const useTerminateClubMembership = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);
  const terminateClubMembership = async ({
    individualId,
    clubId,
    terminationDate,
    terminationReasonId,
  }: terminateClubMembershipArgs) => {
    const requestData = {
      individualId,
      clubId,
      terminationDate,
      terminationReasonId,
    };
    setLoading(true);
    setError(null);
    try {
      const wfService = new Member();
      await wfService.TerminateClubMembership(requestData);
      setLoading(false);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, terminateClubMembership };
};
