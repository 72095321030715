import React, { useEffect } from 'react';

import { Form, Formik } from 'formik';

import { useErrorHandling } from '@use-cases/notifications';

import { zipCodeIsRequired } from '@utils/validation';

import { mapMeetingValuesToContent } from '@domui-domain/clubs';
import { DynamicData } from '@domui-domain/type';
import MeetingFormFields from '@domui-presenters/web/components/ClubsMeetingForm/MeetingFormFields';
import MeetingPreview from '@domui-presenters/web/components/ClubsMeetingForm/MeetingPreview';
import { MeetingFormValues } from '@domui-presenters/web/components/ClubsMeetingForm/types';
import validationSchema from '@domui-presenters/web/components/ClubsMeetingForm/validationSchema';
import ClubsSaveChangesButtons from '@domui-presenters/web/components/ClubsSaveChangesButtons';
import { useFetchLanguages } from 'src/domui/hooks/useFetchLanguages';

import { useTranslation } from '@external/react-i18next';

type Props = {
  initialValues: MeetingFormValues;
  countries: DynamicData;
  handleSubmit: (values: MeetingFormValues) => void;
  onCancelClick: () => void;
};

const MeetingForm: React.FC<Props> = ({
  initialValues,
  countries,
  handleSubmit,
  onCancelClick,
}) => {
  const { t } = useTranslation();

  const {
    data: languages,
    loading: languagesLoading,
    error: languagesError,
    fetchLanguages,
  } = useFetchLanguages();

  useEffect(() => {
    fetchLanguages();
  }, []);

  useErrorHandling(
    languagesError?.message,
    !!languagesError,
    'fetch-languages.error'
  );

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema(t)}
    >
      {({ values, isSubmitting, isValid, dirty }) => {
        const selectCountry: DynamicData = countries?.find(
          (c: DynamicData) => c?.id === values.countryId
        );
        return (
          <Form>
            <div className="flex flex-col mt-8 desktop:flex-row">
              <div className="desktop:w-3/5 desktop:px-6 desktop:mr-8 w-full">
                <MeetingFormFields
                  values={values}
                  countries={countries}
                  languages={languages}
                  languagesLoading={languagesLoading}
                  selectCountry={selectCountry}
                />
                <ClubsSaveChangesButtons
                  isSubmitting={isSubmitting}
                  onCancelClick={onCancelClick}
                  isValid={isValid && dirty}
                  zipCodeIsRequired={
                    zipCodeIsRequired(selectCountry?.id) && !values?.postalCode
                  }
                />
              </div>
              <div className="desktop:w-2/5 w-full">
                <h2>{t('club-editmeeting.preview-title', 'Preview')}</h2>
                <div className="border border-gray-300 py-4 px-3 mb-4 break-all">
                  <MeetingPreview
                    meeting={mapMeetingValuesToContent(
                      languages,
                      countries,
                      values
                    )}
                  />
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MeetingForm;
