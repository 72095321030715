import React, { Dispatch, SetStateAction } from 'react';

import { Pagination } from '@components/Pagination';
import { ResultsList } from '@components/ResultsList';
import { TabInfo } from '@components/TableWithTabs/types';

import { useTranslation } from '@external/react-i18next';

interface ClubMembersListProps {
  pageSize: number;
  areTabsMultiple?: boolean;
  currentPage: number;
  totalCount: number;
  contextMenuTitle?: string;
  tableTitle?: React.ReactNode;
  children: React.ReactNode;
  pageHandler: (event: React.SyntheticEvent, pageNumber: number) => void;
  headerClassName?: string;
  isHeaderPadded?: boolean;
  scrollToTab?: number;
  setActiveTab?: Dispatch<SetStateAction<TabInfo>>;
}

const ClubMembersList: React.FC<ClubMembersListProps> = ({
  children,
  tableTitle,
  pageSize,
  currentPage,
  totalCount,
  areTabsMultiple,
  pageHandler,
  contextMenuTitle,
  headerClassName = '',
  isHeaderPadded = true,
  scrollToTab,
  setActiveTab,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ResultsList
        darkBackground
        scrollToTab={scrollToTab}
        areTabsMultiple={areTabsMultiple}
        headerClassName={`px-5 tablet:px-5 tablet:bg-gray-100 ${headerClassName}`}
        className="-mx-5 tablet:mx-0"
        summary={
          tableTitle || (
            <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
              {t('club-management.club-members', 'Club members')} (
              {totalCount || 0})
            </h2>
          )
        }
        contextMenuTitle={contextMenuTitle}
        isHeaderPadded={isHeaderPadded}
        setActiveTab={setActiveTab}
      >
        {children}
      </ResultsList>
      {totalCount > 0 && (
        <Pagination
          pageSize={pageSize}
          page={currentPage}
          totalCount={totalCount}
          pageHandler={pageHandler}
        />
      )}
    </>
  );
};

export default ClubMembersList;
