import React from 'react';

// import LeadsRosterResultDesktop from '@presenters/web/pages/Leads/LeadsRosterResult/LeadsDesktopRosterResult';
// import LeadsDesktopThreeColumnRosterResult from '@presenters/web/pages/Leads/LeadsRosterResult/LeadsDesktopThreeColumnRosterResult';
// import LeadsRosterResultMobile from '@presenters/web/pages/Leads/LeadsRosterResult/LeadsMobileRosterResult';

import RosterResultDesktop from './DesktopRosterResult/index';
import RosterResultMobile from './MobileRosterResult/index';
// import { RosterResultProps } from '../utils';

// import { RosterResultRowFragment } from '@typings/operations';

// export interface IRosterResulRow
//   extends RosterResultRowFragment,
//     // LeadsMMLPage,
//     RosterResultProps {}

type RosterResultRowFragment = {
  clubId: string;
  nfId: string;
  profile: {
    firstName: string;
    id: string;
    lastName: string;
    middleName: string;
    photoUri: string;
    localizedName: string;
    name: string;
  };
  email: {
    email: string;
    isPrimary: boolean;
    type: string;
  };
  phone: {
    isFax: boolean;
    isPrimary: boolean;
    phone: string;
    type: string;
  };
  roles: {
    endDate: string;
    role: string;
    startDate: string;
  };
  contextMenuItems: {
    id: number;
    label: string;
    path: string;
  }[];
};

const RosterResultRow: React.FC<RosterResultRowFragment> = props => {
  // const { isLeads, isThreeColumn, selectMember, ...prop } = props;
  const { clubId, profile, email, phone, roles, nfId, ...prop } = props;
  // if (isThreeColumn) {
  //   return (
  //     <>
  //       <LeadsDesktopThreeColumnRosterResult
  //         selectMember={selectMember}
  //         {...prop}
  //       />
  //       <LeadsRosterResultMobile selectMember={selectMember} {...prop} />
  //     </>
  //   );
  // }
  // if (isLeads) {
  //   return (
  //     <>
  //       <LeadsRosterResultDesktop selectMember={selectMember} {...prop} />
  //       <LeadsRosterResultMobile selectMember={selectMember} {...prop} />
  //     </>
  //   );
  // }
  return (
    <>
      <RosterResultDesktop
        {...prop}
        clubId={clubId}
        profile={profile}
        email={email}
        phone={phone}
        roles={roles}
        nfId={nfId}
      />
      <RosterResultMobile
        {...prop}
        clubId={clubId}
        profile={profile}
        email={email}
        phone={phone}
        roles={roles}
        nfId={nfId}
      />
    </>
  );
};

export default RosterResultRow;
