import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

// Custom hook to handle API calls
export const useGetTerminationReason = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);
  const fetchTerminationReason = async () => {
    // Clear error before new request
    setLoading(true);
    try {
      setError(null);
      const wfService = new Member();
      const response = await wfService.GetTerminationReason();
      setData(response);
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchTerminationReason };
};
