import React, {
  Reducer,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';

import { useField } from 'formik';
import { isUndefined } from 'lodash';

import { Button } from '@components/Button';
import { WizardPage } from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { useCreateIndividual, useUpdateIndividual } from '@repositories/clubs';

import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';

import {
  Entity,
  IdentificationParameters,
  mapMemberValuesToFormInput,
  MemberDetailsInput,
  MemberDetailsOutput,
  ReplaceOfficerSteps,
  UpdateIndividual,
} from '@domui-domain/clubs';
import { DynamicData } from '@domui-domain/type';
import IdentifyMember from '@domui-presenters/web/components/MembershipManagement/IdentifyMember';
import MemberDetails from '@domui-presenters/web/components/MembershipManagement/MemberDetails';
import { getOfficersPagePath } from '@domui-use-cases/clubs';
import { useFetchIndividual } from 'src/domui/hooks/useFetchIndividual';

import { useTranslation } from '@external/react-i18next';
import { useFetchRiIndividualId } from '@hooks/useFetchRiIndividualId';

export type ReplaceNonMemberProps = {
  clubId: string;
  isRotaractClub: boolean;
  clubName: string;
};

const ReplaceNonMemberToRole: WizardPage<ReplaceNonMemberProps> = ({
  proceed,
  handleBack,
}) => {
  const { t } = useTranslation();

  const [clubId] = useField<string>('clubId');
  const [selectedIndividualProp] = useField<string>('selectedIndividualProp');
  const [isPreviousMemberSelected] = useField<boolean>(
    'selectedPreviousMember'
  );

  const id = clubId?.value;
  const [clubName] = useField<string>('clubName');
  const [, , helpersIndividualToReplace] = useField<UpdateIndividual>(
    'individualToReplace'
  );
  const [, , helpersInitialValues] = useField<UpdateIndividual>(
    'initialIndividualValues'
  );
  const [, , helpersIndividualData] = useField<DynamicData>('individualData');

  const [, , selectedEmailhelper] = useField<string>('email');

  const [isRotaractClubProp] = useField<boolean>('isRotaractClub');
  const isRotaractClub = isRotaractClubProp?.value;
  const [role] = useField<Entity>('role');
  const roleName = getClubRoleTValue(t, role?.value.name || '');
  const [isReplaceNonMemberFlowEligible] = useField<boolean>(
    'isReplaceNonMemberFlowEligible'
  );
  const { addSuccess } = useNotifications();
  const addSuccessRef = useRef(addSuccess);

  const [step, setStep] = useState<ReplaceOfficerSteps>(
    selectedIndividualProp?.value
      ? ReplaceOfficerSteps.INFO
      : ReplaceOfficerSteps.IDENTIFY
  );
  const isFirstStep = step === ReplaceOfficerSteps.IDENTIFY;

  const [filters, setFilters] = useReducer<
    Reducer<IdentificationParameters, IdentificationParameters>
  >((state, action) => {
    return { ...state, ...action };
  }, {});

  // Initiate a state for the current member id.
  // - undefined: no member was selected and the user did not choose to create one
  // - false: no member was selected, the user decided to create a new one
  // - string: the ID of the selected member
  const [selectedMember, selectMember] = useState<undefined | string | false>(
    selectedIndividualProp?.value || false
  );

  // When a member has been selected, attempt to load data from the api.
  // const { data, loading, error } = useFetchMemberDetails(
  //   selectedMember || '',
  //   id
  // );
  const { data, loading, error, fetchIndividual } = useFetchIndividual();

  useErrorHandling(error?.message, !!error, 'member-details.error');

  // If member data has been loaded, message it into InitialData.

  // If member data has been loaded, message it into InitialData.
  const individual = data;
  const initialData = mapMemberValuesToFormInput(
    individual,
    filters.firstName,
    filters.lastName,
    filters.email
  );

  // Member data that has been reviewed or created by the user.
  const [memberDataReviewed, setMemberDataReviewed] = useState<
    MemberDetailsInput | undefined | null
  >();

  // If we have reviewed member data, massage it into a mutation payload.
  const [, setMemberDataPayload] = useState<MemberDetailsOutput | undefined>();

  const [, setMemberId] = useState<undefined | string>();
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const handleBackClick = () => {
    if (isFieldTouched) {
      show(true);
    } else if (isFirstStep) {
      localizedNavigate(getOfficersPagePath(id));
    } else {
      setStep(step - 1);
    }
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: handleBackClick,
  });

  const [
    ,
    {
      error: createIndividualError,
      called: createIndividualCalled,
      loading: createIndividualLoading,
    },
  ] = useCreateIndividual();

  const [
    ,
    {
      error: updateIndividualError,
      called: updateIndividualCalled,
      loading: updateIndividualLoading,
    },
  ] = useUpdateIndividual();

  const [
    ,
    { data: fetchRiIndividualIdData, loading: fetchRiIndividualLoading },
  ] = useFetchRiIndividualId();

  useEffect(() => {
    if (fetchRiIndividualIdData) {
      setMemberId(String(fetchRiIndividualIdData.getIndividual.riIndividualId));
    }
  }, [fetchRiIndividualIdData]);
  useEffect(() => {
    fetchIndividual(selectedMember || '');
  }, [selectedMember]);

  useEffect(() => {
    if (
      (createIndividualCalled && !createIndividualError) ||
      (updateIndividualCalled && !updateIndividualError)
    ) {
      setGlobal(false);
    }
  }, [
    t,
    memberDataReviewed,
    addSuccessRef,
    id,
    createIndividualCalled,
    createIndividualError,
    updateIndividualCalled,
    updateIndividualError,
  ]);

  const modalOnConfirmHandler = () => {
    globalHide();
    if (isFirstStep) {
      handleBack();
    } else {
      setStep(step - 1);
    }
  };

  const handleFormFieldsTouched = (isTouched: boolean) => {
    setIsFieldTouched(isTouched);
  };

  const handleCancelClick = () => {
    if (isFieldTouched) {
      show(true);
    } else {
      setStep(0);
      handleBack();
    }
  };

  const BackButton = () =>
    useMemo(() => {
      return (
        <>
          <Button type="button" clickHandler={handleCancelClick} text full>
            {t('add_member.identify_member_form.cancel_button', 'Cancel')}
          </Button>
          <LeaveFormConfirmationModal
            isOpen={isShowing}
            closeModal={globalHide}
            onConfirm={modalOnConfirmHandler}
          />
        </>
      );
    }, [isShowing]);

  if (
    loading ||
    createIndividualLoading ||
    updateIndividualLoading ||
    fetchRiIndividualLoading
  ) {
    return <Loading />;
  }

  const handleSubmit = (
    formData: MemberDetailsInput,
    dataToUpdate: MemberDetailsOutput,
    initialValues: UpdateIndividual,
    data: DynamicData
  ) => {
    if (formData && dataToUpdate) {
      // selectedMember to be passed along with
      const params: UpdateIndividual = {
        id: selectedMember || '',
        ...dataToUpdate,
        name: formData.localizedName,
      };
      helpersIndividualToReplace.setValue(params);
      helpersInitialValues.setValue(initialValues);
      helpersIndividualData.setValue(data);
      selectedEmailhelper.setValue(formData.email || '');
    }
  };

  switch (step) {
    case ReplaceOfficerSteps.IDENTIFY:
      return (
        <IdentifyMember
          submitHandler={(id: string | false | undefined) => {
            setMemberDataReviewed(null);
            selectMember(id);
            if (!isUndefined(id)) {
              setStep(ReplaceOfficerSteps.INFO);
            }
          }}
          filters={filters}
          setFilters={setFilters}
          isRotaractClub={isRotaractClub}
          clubName={clubName?.value}
          handleFormFieldsTouched={handleFormFieldsTouched}
          isCreateAssignFlowEligible={isReplaceNonMemberFlowEligible?.value}
          roleName={roleName}
        >
          <BackButton />
        </IdentifyMember>
      );
    case ReplaceOfficerSteps.INFO:
      return (
        <MemberDetails
          id={id}
          data={memberDataReviewed || initialData}
          selectedPreviousMember={isPreviousMemberSelected?.value}
          initialData={initialData}
          isCreateAssignFlowEligible={isReplaceNonMemberFlowEligible?.value}
          roleName={roleName}
          submitHandler={(formData, dataToUpdate, initialValues) => {
            setMemberDataReviewed(formData);
            setMemberDataPayload(dataToUpdate);
            handleSubmit(formData, dataToUpdate, initialValues, data);
            proceed();
          }}
          handleFormFieldsTouched={handleFormFieldsTouched}
        >
          <BackButton />
        </MemberDetails>
      );
    default:
      return null;
  }
};

export default ReplaceNonMemberToRole;
