// Libs
import { TFunction } from 'i18next';
import moment from 'moment';
import * as Yup from 'yup';

const requiredMessage = (t: TFunction) =>
  t('forms.required', 'This field is required.');
const invalidDateMessage = (t: TFunction) =>
  t(
    'terminate-member.submit-terminate-member-form.invalid-date',
    'Termination date must not be more than 30 days in the future or past.'
  );

export const informationFormSchema = (t: TFunction, isHonorary: boolean) =>
  Yup.object({
    terminationDate: Yup.date()
      .required(requiredMessage(t))
      .min(
        moment()
          .subtract({ day: 30 })
          .toDate(),
        invalidDateMessage(t)
      )
      .max(
        moment()
          .add({ day: 30 })
          .toDate(),
        invalidDateMessage(t)
      ),
    terminationReason: isHonorary
      ? Yup.string()
      : Yup.string().required(requiredMessage(t)),
  });

export const confirmationFormSchema = (t: TFunction) =>
  Yup.object({
    terminationConfirmation: Yup.boolean().oneOf([true], requiredMessage(t)),
  });
