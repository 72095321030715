import React, { useEffect } from 'react';

import { RouteComponentProps } from '@reach/router';
import { toString } from 'lodash';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';

import ConfirmationForm from './ConfirmationForm';
import InformationForm from './InformationForm';
import {
  confirmationFormSchema,
  informationFormSchema,
} from './validationShema';

import {
  ROTARACT_HONORARY_TERMINATION_REASON_ID,
  ROTARY_HONORARY_TERMINATION_REASON_ID,
  TerminateMembershipValues,
} from '@domain/clubs';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { useMembershipActions } from '@domui-hooks/useMembershipActions';
import { useTerminateClubMembership } from '@domui-hooks/useTerminateClubMembership';
import { getClubMembersPath, isHonoraryMember } from '@domui-use-cases/clubs';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

export type TerminateMembershipProps = {
  clubId: string;
  individualId: string;
} & RouteComponentProps;

const TerminateMembership: React.FC<TerminateMembershipProps> = ({
  clubId,
  individualId,
}) => {
  const { t } = useTranslation();

  const {
    data,
    loading,
    // error,
    fetchMembershipActions,
  } = useMembershipActions();

  useEffect(() => {
    if (clubId && individualId) {
      const orgId = clubId;
      fetchMembershipActions(orgId, individualId);
    }
  }, [clubId, individualId]);

  // const { data, loading } = useFetchMembershipInfo(clubId, membershipId);

  const {
    terminateClubMembership,
    error: errorTerminateMembership,
  } = useTerminateClubMembership();

  const { addError, addSuccess } = useNotifications();

  if (loading) {
    return <Loading />;
  }

  // const { club } = data || {};

  const {
    admissionDate,
    memberType: membershipType,
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  } = data?.member || {};

  const individual = {
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  };

  // const { orgClubType } = club || {};

  const isHonorary = isHonoraryMember(membershipType);

  const terminatedOtherId =
    membershipType === MembershipType.RotaractHonorary
      ? ROTARACT_HONORARY_TERMINATION_REASON_ID
      : ROTARY_HONORARY_TERMINATION_REASON_ID;

  InformationForm.schema = informationFormSchema(t, isHonorary);
  ConfirmationForm.schema = confirmationFormSchema(t);

  const handleSubmit = async (values: TerminateMembershipValues) => {
    if (clubId && individualId) {
      await terminateClubMembership({
        individualId,
        clubId,
        terminationDate: moment(values.terminationDate).format('YYYY-MM-DD'),
        terminationReasonId: !isHonorary
          ? values.terminationReason
          : terminatedOtherId, // "Terminated - Other"
      });

      if (errorTerminateMembership) {
        addError(
          t(
            'terminate-member.submit-terminate-member-form.error',
            'An error happened.'
          ),
          { id: 'form.error' }
        );

        addError(errorTerminateMembership?.message || '');
      } else {
        addSuccess(
          t(
            'terminate-member.submit-terminate-member-form.success',
            'Success.'
          ),
          { id: 'form.success' }
        );

        localizedNavigate(getClubMembersPath(clubId));
      }
    }
  };

  return (
    (clubId && (
      <Wizard
        initialValues={{
          terminationReason: '',
          terminationConfirmation: false,
        }}
        onSubmit={handleSubmit}
        pages={[InformationForm, ConfirmationForm]}
        pageProps={{
          clubId,
          // clubType: { orgClubType },
          admissionDate: toString(admissionDate),
          memberName: toString(getFullName(individual)),
          membershipType: toString(membershipType),
        }}
      />
    )) ||
    null
  );
};

export default TerminateMembership;
