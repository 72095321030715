import React, { useEffect } from 'react';

import Select, { SelectOptions, SelectProps } from '@components/Formik/Select';

import { useGetTerminationReason } from '@domui-hooks/useGetTerminationReason';

import { useTranslation } from '@external/react-i18next';

export type TerminationReasonSelectProps = Omit<SelectProps, 'options'> & {
  // clubType: string;
};

export type TerminationReason = {
  terminationReasonPk: string;
  reason: string;
};

export const TerminationReasonSelect: React.FC<TerminationReasonSelectProps> = props => {
  const { t } = useTranslation();
  const {
    data,
    loading,
    error,
    fetchTerminationReason,
  } = useGetTerminationReason();
  useEffect(() => {
    fetchTerminationReason();
  }, []);
  // const { clubType } = props;
  const useTerminationReasonOptions = () => {
    // const { data, error, loading } = useTerminationReasons(clubType);
    const options: SelectOptions =
      data?.map(({ terminationReasonPk, reason }: TerminationReason) => {
        return {
          value: terminationReasonPk,
          label: t(reason),
        };
      }) || [];
    return { options, error: error?.message, loading };
  };
  return <Select {...props} options={useTerminationReasonOptions} />;
};
