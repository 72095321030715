/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

// Custom hook to handle API calls
export const useMembershipActions = () => {
  const [data, setData] = useState<DynamicData>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const fetchMembershipActions = async (
    orgId: string,
    individualId: string
  ) => {
    // Clear error before new request
    setLoading(true);
    try {
      if (orgId && individualId) {
        setError(null);
        const wfService = new Member();
        const response = await wfService.MembershipActions(orgId, individualId);
        const replaceHonoraryMember = async () => {
          if (response.member.memberType === 'Honorary Member') {
            response.member.memberType = 'Honorary';
          }
          return response;
        };

        const updatedData = await replaceHonoraryMember();
        setData(updatedData);
      }
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return { data, loading, error, fetchMembershipActions };
};
