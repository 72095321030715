import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

type removeOfficerArgs = {
  individualId: string;
  clubId: string;
  admissionDate: string;
  memberType: string;
  sponsorIds: DynamicData;
};

// Custom hook to handle API calls
export const useEditClubMembership = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const editClubMembership = async ({
    individualId,
    clubId,
    admissionDate,
    memberType,
    sponsorIds,
  }: removeOfficerArgs) => {
    const requestData = {
      individualId,
      clubId,
      admissionDate,
      memberType,
      sponsorIds,
    };
    let status = false;
    setLoading(true);
    setError(null);
    try {
      const wfService = new Member();
      await wfService.EditClubMembership(requestData);
      status = true;
      setLoading(false);
    } catch (error) {
      setError(error as DomuiError);
      status = false;
      setLoading(false);
    } finally {
      setLoading(false);
    }
    return status;
  };

  return { loading, error, editClubMembership };
};
