/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { useField } from 'formik';
import { orderBy, toNumber } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import { WizardPage } from '@components/Formik/Wizard';
import IdentifyOfficerResult from '@components/IdentifyOfficerResult';
import Loading from '@components/Loading';
import MemberRow from '@components/MemberRow';
import PrevOfficerListItem from '@components/PrevOfficerListItem';
import { ResultsListItem } from '@components/ResultsList';
import TableWithTabs from '@components/TableWithTabs';
import { TabInfo } from '@components/TableWithTabs/types';
import Title from '@components/Title';

import { PREV_CES_YEARS, RolesForSelectClubOfficers } from '@domain/clubs';
import { Club, Entity, Individual, Period } from '@domain/clubs/types';
import { CLUB_EXECUTIVE_SECRETARY } from '@domain/districts';

import { isCES } from '@use-cases/clubs/helpers/isCES';
import { isRotaractAdvisor } from '@use-cases/clubs/helpers/isRotaractAdvisor';
import { useErrorHandling } from '@use-cases/notifications';

// import {
//   useFetchPreviousOfficers,
//   useSearchClubOfficer,
// } from '@repositories/clubs';

import { getRotaryYear, isFutureRY } from '@utils/datetime';
import { getClubOfficerCalendarStartDate } from '@utils/getClubOfficerCalendarStartDate';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';

import ClubMembersList from '@domui-components/ClubMembersList';
import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import { SearchFormValues } from '@domui-components/Formik/SearchMembersForm/types';
import { CES_ROLEID } from '@domui-domain/clubs';
import { useClubMembersSearch } from '@domui-hooks/useClubMembersSearch';
import { useFetchPreviousOfficers } from '@domui-hooks/useFetchPreviousOfficers';
import { isRotaractClub } from '@domui-utils/isRotaractClub';

import { useTranslation } from '@external/react-i18next';

import { Leadership, MembershipStatusFilter } from '@typings/operations';

type SearchFields = {
  name?: string;
  email?: string;
};
export type clubProps = {
  id: string;
  name: string;
  clubType?: string;
};
export type SelectOfficerProps = {
  club: clubProps;
  role: Entity;
  slot: Period;
  individual?: Individual;
  termStartDate?: string;
  isCreateAssignFlowEligible?: boolean;
};

type ProfileProps = {
  id: string;
  firstName: string;
  middleName: string;
  lastName: string;
  localizedName: string;
  photoUri: string;
  name: string;
};

type EmailProps = {
  email: string;
  type: string;
  isPrimary: boolean;
};

type PhoneProps = {
  phone: string;
  isFax: boolean;
  type: string;
  isPrimary: boolean;
};
type RoleProps = {
  startDate: string;
  endDate: string;
  role: string;
};

type MemberProps = {
  profile: ProfileProps;
  nfId: string;
  email: EmailProps[];
  phone: PhoneProps[];
  roles: RoleProps[];
  otherEmails: EmailProps[];
  status: string;
};

type LeadershipProps = {
  id: string;
  role: string;
  startDate: string;
  endDate: string;
};

const TAB_CLUB_MEMBERS = 'Club Members';
const TAB_PREVIOUS_RA = 'Previous Rotaract Advisors';
const TAB_PREVIOUS_CES = 'Previous Executive Secretary/Director';
const PAGE_SIZE = 10;

export type SelectMemberTabs =
  | typeof TAB_CLUB_MEMBERS
  | typeof TAB_PREVIOUS_CES;

const SelectOfficer: WizardPage<SelectOfficerProps> = ({ setOtherEmails }) => {
  const { t } = useTranslation();

  const [individual, , helpers] = useField<Individual>('individual');

  const [, , helpersIndividualToReplace] = useField<Individual>(
    'individualToReplace'
  );
  const [, , helpersSelectedIndividualProp] = useField<string>(
    'selectedIndividualProp'
  );

  const [, , selectedPreviousMember] = useField<boolean>(
    'selectedPreviousMember'
  );
  const [, , emailHelpers] = useField<string>('email');
  const [club] = useField<Club>('club');
  const [role] = useField<Entity>('role');
  const roleName = getClubRoleTValue(t, role?.value.name || '');
  const [clubType] = useField<String>('clubType');
  const [isCreateAssignFlowEligible] = useField<Club>(
    'isCreateAssignFlowEligible'
  );
  const [slot, , slotHelpers] = useField<Period>('slot');
  const [isMemberRow] = useField<boolean>('isMemberRow');
  const [isReplaceNonMemberFlowEligible] = useField<boolean>(
    'isReplaceNonMemberFlowEligible'
  );
  const isReplaceMemberFromRolePage = isMemberRow.value;
  const [termStartDate] = useField<string>('termStartDate');
  const formattedTermStartDate = moment(termStartDate.value).format(
    'YYYY-MM-DD'
  );

  const {
    data: previousOfficersData,
    loading: previousOfficerLoading,
    error: previousOfficerError,
    fetchPreviousOfficers,
  } = useFetchPreviousOfficers();

  // const [
  //   fetchPreviousRA,
  //   { data: raMembersData, loading: raMembersLoading },
  // ] = useFetchPreviousOfficers();

  const [searchFields, setSearchFields] = useState<SearchFields>({});

  const [currentPage, setCurrentPage] = useState(1);

  const [activeTab, setActiveTab] = useState<TabInfo>({
    name: TAB_CLUB_MEMBERS,
    scrollTo: 0,
  });

  const paginationHandler = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const handleSearch = ({ name, email }: SearchFormValues) => {
    setSearchFields({ name, email });
    setCurrentPage(1);
  };
  const membershipType = isRotaractClub(club.value.clubType)
    ? MembershipStatusFilter.Rotaractor
    : MembershipStatusFilter.Active;

  // const { data, loading, error } = useSearchClubOfficer(
  //   club.value.id,
  //   currentPage,
  //   PAGE_SIZE,
  //   searchFields.name,
  //   searchFields.email,
  //   membershipType
  // );

  const payload = {
    orgId: club?.value.id,
    name: searchFields.name,
    email: searchFields.email,
    filters: {
      roles: [],
      memberTypes: [membershipType],
    },
  };

  const { data, loading, error, clubMembersSearch } = useClubMembersSearch();
  useEffect(() => {
    if (membershipType) {
      clubMembersSearch(currentPage, PAGE_SIZE, payload);
    }
  }, [currentPage, PAGE_SIZE, searchFields]);
  const otherEmails = data?.memberSearchInfo?.map((member: MemberProps) => {
    return member.email.filter((email: EmailProps) => email.isPrimary !== true);
  });

  const { riClubId } = club.value;

  const selectedEndDateRY =
    isMemberRow.value && !isFutureRY(slot.value.end.year())
      ? Number(getRotaryYear())
      : slot.value.end.year();

  const currentRY = Number(getRotaryYear());
  const selectedRY = selectedEndDateRY || currentRY;
  const yearRange = selectedRY - PREV_CES_YEARS;
  const isCESRoleSelected = isCES(role.value.name) || isCES(role.name);

  // const isRotaractAdvisorRole = isRotaractAdvisor(role.value.id);
  const previousOfficersPayload = {
    roleId: CES_ROLEID,
    clubId: club?.value.id,
  };

  useEffect(() => {
    if (selectedEndDateRY >= currentRY && isCESRoleSelected)
      fetchPreviousOfficers(currentPage, PAGE_SIZE, previousOfficersPayload);
  }, [selectedEndDateRY]);

  // useEffect(() => {
  //   if (isRotaractAdvisorRole && selectedEndDateRY >= currentRY && riClubId) {
  //     fetchPreviousRA({
  //       variables: {
  //         riClubId,
  //         yearRange: [selectedRY, yearRange],
  //         clubRole: RolesForSelectClubOfficers.ClubRotractAdvisor,
  //         pageSize: PAGE_SIZE,
  //         page: currentPage,
  //       },
  //     });
  //   }
  // }, []);

  useErrorHandling(error?.message, !!error, 'search-officer.error');

  const { id: individualId = '', name: individualName = '', photoUri = '' } =
    individual.value || {};

  const handleSelect = (
    id: string,
    name: string,
    email: string,
    membershipAdmissionDate?: string | null,
    membershipTerminationDate?: string | null,
    otherEmails?: string[] | null,
    onlineId?: string | null
  ) => {
    const params = {
      id,
      name,
      email,
      membershipAdmissionDate,
      membershipTerminationDate,
      otherEmails,
      onlineId,
    };

    setOtherEmails?.(otherEmails || []);
    if (isReplaceMemberFromRolePage) {
      helpersIndividualToReplace.setValue(params);
    } else {
      helpers.setValue(params);
      slotHelpers.setValue({
        start: getClubOfficerCalendarStartDate(
          formattedTermStartDate,
          membershipAdmissionDate
        ),
        end: slot.value.end,
      });
    }

    if (
      isCreateAssignFlowEligible?.value ||
      isReplaceNonMemberFlowEligible?.value
    ) {
      helpersSelectedIndividualProp.setValue(id);

      if (isReplaceNonMemberFlowEligible?.value) {
        selectedPreviousMember.setValue(true);
      }

      if (isCreateAssignFlowEligible?.value) {
        helpersIndividualToReplace.setValue(params);
      }
    }
    emailHelpers.setValue(email);
    window.scrollTo(0, 0);
  };

  const { totalCount } = data || {};

  const sortedCESOfficers = orderBy(
    previousOfficersData?.PreviousOfficers,
    member => [
      member?.leadershipsFromThisClub.find((leadership: LeadershipProps) =>
        isCES(leadership.role)
      )?.startDate,
    ],
    'desc'
  );
  // const sortedRAOfficers = orderBy(
  //   raMembersData?.previousOfficers.results,
  //   member => [
  //     member?.leadershipsFromThisClub.find(
  //       leadership =>
  //         leadership.role === RolesForSelectClubOfficers.ClubRotractAdvisor
  //     )?.startDate,
  //   ],
  //   'desc'
  // );

  const getLeadershipsByDate = (
    leaderships: Leadership[],
    roleName: string
  ) => {
    const rolesList = leaderships.filter(
      ({ role, endDate }) =>
        roleName === role && moment(endDate).year() >= yearRange
    );

    return orderBy(rolesList, ['endDate'], ['desc']);
  };

  const cesTab = {
    name: TAB_PREVIOUS_CES,
    title: `${t('club-management.club-previous-ces', TAB_PREVIOUS_CES)} (${
      previousOfficersData?.totalCount
    })`,
    component: (
      <ClubMembersList
        totalCount={previousOfficersData?.totalCount || 0}
        headerClassName="-m-2 px-14"
        areTabsMultiple
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={paginationHandler}
      >
        {sortedCESOfficers?.map(member => (
          <ResultsListItem key={member.id}>
            <PrevOfficerListItem
              isCES
              name={member?.name}
              individualId={member?.id}
              photoUri={member?.photoUri}
              type={member?.membershipsFromThisClub[0]?.type || 'Non-member'}
              email={member?.primaryEmail?.email || ''}
              phoneNumber={member?.primaryPhone?.phone || ''}
              leaderships={getLeadershipsByDate(
                member.leadershipsFromThisClub as Leadership[],
                RolesForSelectClubOfficers.ClubExecutiveSecretaryOrDirector
              )}
              selectMember={value => {
                handleSelect(
                  value,
                  member?.name,
                  member?.email || '',
                  member?.membershipsFromThisClub[0]?.admissionDate || '',
                  member?.membershipsFromThisClub[0]?.terminationDate || ''
                );
              }}
              expanded={totalCount === 1}
            />
          </ResultsListItem>
        ))}
      </ClubMembersList>
    ),
  };

  const membersTab = {
    name: TAB_CLUB_MEMBERS,
    title: `${t(
      'club-management.club-members',
      TAB_CLUB_MEMBERS
    )} (${totalCount})`,

    component: (
      <ClubMembersList
        totalCount={toNumber(data?.totalCount)}
        headerClassName="-my-2 px-5"
        pageSize={PAGE_SIZE}
        currentPage={currentPage}
        pageHandler={paginationHandler}
      >
        {data?.memberSearchInfo?.map((member: MemberProps) => (
          <ResultsListItem key={member?.profile?.id} spacingY={0}>
            <IdentifyOfficerResult
              individualIdDisabled={individualId}
              isReplaceOperation={isReplaceMemberFromRolePage}
              name={member?.profile?.name}
              individualId={member?.profile?.id}
              photoUri={member?.profile.photoUri}
              role={
                (member?.roles[0]?.role &&
                  getClubRoleTValue(t, member?.roles[0]?.role)) ||
                ''
              }
              roleId={role.value.id}
              type={member?.roles?.[0]?.role || ''}
              admissionDate={member?.roles?.[0]?.startDate || ''}
              email={member?.email?.[0]?.email || ''}
              phoneNumber={member?.phone?.[0]?.phone || ''}
              selectMember={value => {
                handleSelect(
                  value,
                  member?.profile?.name || '',
                  member?.email?.[0]?.email || '',
                  member?.roles?.[0]?.startDate || '',
                  member?.roles?.[0]?.endDate || '',
                  otherEmails,
                  member?.email?.[0]?.email
                );
              }}
              expanded={totalCount === 1}
            />
          </ResultsListItem>
        ))}
      </ClubMembersList>
    ),
  };

  // const previousRATab = {
  //   name: TAB_PREVIOUS_RA,
  //   title: `${t('club-management.club-previous-ra', TAB_PREVIOUS_RA)} (${
  //     raMembersData?.previousOfficers.totalCount
  //   })`,
  //   component: (
  //     <ClubMembersList
  //       totalCount={raMembersData?.previousOfficers.totalCount || 0}
  //       headerClassName="-m-2 px-14"
  //       areTabsMultiple
  //       pageSize={PAGE_SIZE}
  //       currentPage={currentPage}
  //       pageHandler={paginationHandler}
  //     >
  //       {sortedRAOfficers?.map(member => (
  //         <ResultsListItem key={member.id}>
  //           <PrevOfficerListItem
  //             name={member.name}
  //             individualId={member.id}
  //             photoUri={member.photoUri}
  //             type={member.membershipsFromThisClub[0]?.type || 'Non-member'}
  //             email={member.email || ''}
  //             phoneNumber={member.phoneNumber || ''}
  //             leaderships={getLeadershipsByDate(
  //               member.leadershipsFromThisClub as Leadership[],
  //               RolesForSelectClubOfficers.ClubRotractAdvisor
  //             )}
  //             selectMember={value => {
  //               handleSelect(
  //                 value,
  //                 member.name,
  //                 member.email || '',
  //                 member.membershipsFromThisClub[0]?.admissionDate || '',
  //                 member.membershipsFromThisClub[0]?.terminationDate || ''
  //               );
  //             }}
  //             expanded={totalCount === 1}
  //           />
  //         </ResultsListItem>
  //       ))}
  //     </ClubMembersList>
  //   ),
  // };

  // eslint-disable-next-line prefer-const
  let switchTab = false;
  const tabs = [membersTab];
  if (isCESRoleSelected && previousOfficersData?.totalCount) {
    tabs.push(cesTab);
    switchTab = true;
  }

  // if (isRotaractAdvisorRole && raMembersData?.previousOfficers.totalCount) {
  //   tabs.push(previousRATab);
  //   switchTab = true;
  // }

  useEffect(() => {
    if (tabs.length > 1) setActiveTab(tabs[1]);
  }, [switchTab]);

  const addIndividualHandler = () => {
    helpersSelectedIndividualProp.setValue('');
    selectedPreviousMember.setValue(false);
  };

  const titleValue = isReplaceMemberFromRolePage
    ? t(
        'club-replace-officer.select-member-replace.title',
        'Select a Member to Replace'
      )
    : t('club-add-officer.select-member.title', 'Select a member');

  if (loading) {
    // || membersLoading || raMembersLoading) {
    return <Loading />;
  }

  return (
    <>
      {isCreateAssignFlowEligible?.value ||
      isReplaceNonMemberFlowEligible?.value ? (
        <>
          <Title className="h2 mb-0 mt-7">
            {t('club-officer.select-member.title', 'Select  {{name}}', {
              name: roleName,
            })}
          </Title>
          {isReplaceNonMemberFlowEligible?.value && (
            <MemberRow
              id={individualId}
              fullName={individualName}
              photoUri={photoUri || ''}
              memberInfo={roleName}
              isLinkToProfile
              hasCollapsePartsMobile={false}
              hasNoBorder
            />
          )}
          <p className="mb-8">
            {t(
              'club-officer.select-member.description',
              'Select a  {{name}} from your previously assigned individuals or add a new  {{name}}',
              { name: roleName }
            )}
          </p>
          <Button
            clickHandler={() => addIndividualHandler()}
            secondary
            small
            className="mb-4 desktop:mb-8"
          >
            {t('club-oficer.add-new-member.button', 'Add new {{name}}', {
              name: roleName,
            })}
          </Button>
        </>
      ) : (
        <>
          <Title className="h2 mb-5 mt-7">{titleValue}</Title>
          {isReplaceMemberFromRolePage ? (
            <MemberRow
              id={individualId}
              fullName={individualName}
              photoUri={photoUri || ''}
              memberInfo={roleName}
              isLinkToProfile
              hasCollapsePartsMobile={false}
              wrapperClass="mobile:px-0 tablet:px-5"
              hasNoBorder
            />
          ) : (
            <p className="mb-12">
              {t(
                'club-add-officer.select-member.description',
                'Select a member for the role of <b>{{ role }}</b> for {{ startDate }} through {{ endDate }}.',
                {
                  role: roleName,
                  startDate: formattedTermStartDate,
                  endDate: moment(slot.value.end).format('YYYY-MM-DD'),
                }
              )}
            </p>
          )}
        </>
      )}
      <SearchMembersForm
        initialValues={searchFields as SearchFormValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'club-add-officer.select-member.search-members-title',
            'Search club members'
          ),
        }}
        isLabelsHidden
        disabled={
          activeTab.name === TAB_PREVIOUS_CES ||
          activeTab.name === TAB_PREVIOUS_RA
        }
      />

      <TableWithTabs
        activeTabInfo={activeTab}
        tabs={tabs}
        onChangeTab={setActiveTab}
      />
    </>
  );
};

export default SelectOfficer;
