import React, { useEffect, useMemo, useState } from 'react';

import { Form, Formik } from 'formik';
import { identity, pipe } from 'fp-ts/lib/function';
import { isEmpty } from 'lodash';
import { union } from 'lodash/fp';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import Checkbox from '@components/Formik/Checkbox';
import Title from '@components/Title';

import { getCalendarMaxAndMinDate, getMembershipType } from './utils';
import validationSchema from './validationSchema';

import { FormMembershipData, MembershipData } from '@domain/clubs';

import { useTouchedFormFields } from '@use-cases/clubs';

import Select from '@domui-components/Formik/Select';
import { useFetchPossibleRoles } from '@domui-hooks/useFetchPossibleRoles';
import SponsorSelect from '@domui-presenters/web/components/Formik/SponsorSelect/SponsorSelect';
import Loading from 'src/domui/components/Loading';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/graphql';
import { MembershipForAddMember } from '@typings/operations';

const DATE_FORMAT = 'YYYY-MM-DD';

const MembershipInformation: React.FC<{
  isRotaractClub: boolean;
  // hasActiveMembership: boolean;
  recentMemberships: MembershipForAddMember[];
  submitHandler: (data: Partial<MembershipData>) => void;
  clubId: string;
  handleFormFieldsTouched?: (isTouched: boolean) => void;
  individualId: undefined | string | false;
}> = ({
  children,
  submitHandler,
  clubId,
  recentMemberships,
  isRotaractClub,
  handleFormFieldsTouched,
  individualId,
}) => {
  const { t } = useTranslation();
  const { checkTouchedFields } = useTouchedFormFields(handleFormFieldsTouched);
  const [hasActiveMembership, setActiveMembership] = useState<null | boolean>(
    null
  );

  const {
    data: roleData,
    loading: rolesLoading,
    fetchPossibleRoles,
  } = useFetchPossibleRoles();
  const { activeMembershipType, honoraryMembershipType } = getMembershipType(
    isRotaractClub
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const checkActiveMermbership = (roleData: any) => {
    if (roleData?.possibleRoles && roleData?.possibleRoles.length > 0) {
      const roles = roleData?.possibleRoles?.[0]?.possibleRoles?.join(',');

      if (roles.includes('Honorary') && roles.includes('Active')) {
        return false;
      }
      if (roles.includes('Honorary') && !roles.includes('Active')) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const active = checkActiveMermbership(roleData);
    setActiveMembership(active);
  }, [roleData]);

  const memberTypeOptions = useMemo(
    () =>
      pipe(
        [
          {
            value: honoraryMembershipType,
            label: t('membership.type.honorary', 'Honorary'),
          },
        ],
        hasActiveMembership
          ? identity
          : union([
              {
                value: activeMembershipType,
                label: t('membership.type.member', 'Active'),
              },
            ])
      ),
    [hasActiveMembership]
  );
  useEffect(() => {
    fetchPossibleRoles(individualId);
  }, [individualId]);
  const getIsSponsoredByDisabled = (values: FormMembershipData): boolean =>
    !(values.admissionDate && values.memberType === MembershipType.Member);

  const initialValues: FormMembershipData = {
    procedureCertification: false,
    memberType:
      memberTypeOptions.length === 1 ? memberTypeOptions[0].value : undefined,
    admissionDate: undefined,
    sponsoredBy: undefined,
  };

  if (rolesLoading) {
    return <Loading />;
  }
  return (
    <>
      <Title className="h2 mt-4 mb-2">
        {t('add-member.add-member-form.title-info', 'Add Membership')}
      </Title>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={({ memberType, admissionDate: date, sponsoredBy }) => {
          const result: MembershipData = {
            admissionDate: moment(date).format(DATE_FORMAT),
            memberType: memberType as MembershipType,
            sponsoredBy,
          };

          submitHandler(result);
        }}
        validationSchema={validationSchema(t)}
      >
        {({ values, isSubmitting, errors, setFieldValue }) => {
          const isSubmitDisabled =
            !isEmpty(errors) || !values.procedureCertification || isSubmitting;

          checkTouchedFields(initialValues, values);

          const { minDate, maxDate } = getCalendarMaxAndMinDate(
            recentMemberships,
            clubId,
            values.memberType,
            isRotaractClub
          );

          return (
            <Form>
              <div className="max-w-lg">
                <Select
                  label={t(
                    'add-member.add-member-form.member-type',
                    'Member type'
                  )}
                  name="memberType"
                  options={memberTypeOptions}
                  searchable
                  clearable
                  required
                  onChange={() => {
                    setFieldValue('admissionDate', null);
                  }}
                />
                <Calendar
                  name="admissionDate"
                  label={t(
                    'add-member.add-member-form.admission-date',
                    'Admission date'
                  )}
                  minDate={minDate}
                  maxDate={maxDate}
                  placeholder={DATE_FORMAT}
                  required
                  disabled={!values.memberType}
                />
                {!isRotaractClub && (
                  <SponsorSelect
                    selectId="sponsoredBy"
                    selectName="sponsoredBy"
                    selectLabel={t(
                      'add-member.add-member-form.sponsored-by',
                      'Sponsored by'
                    )}
                    description={t(
                      'add-member.add-member-form.sponsors-maximum',
                      '2 sponsors maximum'
                    )}
                    clubId={clubId}
                    disabled={getIsSponsoredByDisabled(values)}
                    // admissionDate={
                    //   getIsSponsoredByDisabled(values)
                    //     ? undefined
                    //     : moment(values.admissionDate).format(DATE_FORMAT)
                    // }
                  />
                )}
                <Checkbox
                  name="procedureCertification"
                  label={t(
                    'add-member.add-member-form.procedure-certification',
                    'Procedure certification'
                  )}
                  description={t(
                    'add-member.add-member-form.procedure-certification-description',
                    'I certify that this individual was terminated in accordance with our club bylaws and understands and accepts the conditions of termination.'
                  )}
                  required
                />
                <div className="mt-15 flex flex-col">
                  <Button
                    className="max-w-lg w-full mb-8"
                    disabled={isSubmitDisabled}
                  >
                    {t('continue', 'Continue')}
                  </Button>
                  {children}
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default MembershipInformation;
