import * as React from 'react';
import { useEffect, useState } from 'react';

import classNames from 'classnames';
import { isEqual, isUndefined } from 'lodash';

import OptionItem from '@components/Formik/MultiSelectFilter/OptionItem';
import { Option } from '@components/Formik/MultiSelectFilter/types';
import { FormElement } from '@components/Forms/FormElement';

import { customStyles } from './customStyles';

import { useFetchPossibleSponsors } from '@domui-hooks/useFetchPossibleSponsors';
import { useMembershipActions } from '@domui-hooks/useMembershipActions';

import { useTranslation } from '@external/react-i18next';
import ReactSelect, { OptionTypeBase, ValueType } from '@external/react-select';

export interface SponsorSelectProps {
  selectId: string;
  selectName: string;
  selectLabel: string;
  selectPlaceholder?: string;
  selectClassName?: string;
  clubId: string;
  currentIndividualId?: string;
  handleChange?: (individualIds: string[]) => void;
  disabled?: boolean;
  description?: string;
  setisSubmitButtonDisabled?: (isSubmitButtonDisabled: boolean) => void;
}

type SponsorProp = {
  nameWithPrefixSuffix: string;
  id: string;
};

export const SponsorSelect: React.FC<SponsorSelectProps> = ({
  selectId,
  selectName,
  selectLabel,
  selectPlaceholder,
  selectClassName,
  clubId,
  currentIndividualId,
  handleChange,
  disabled = false,
  description,
  setisSubmitButtonDisabled,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState<string>('');

  const {
    data,
    // loading,
    // error,
    fetchMembershipActions,
  } = useMembershipActions();

  useEffect(() => {
    if (clubId && currentIndividualId) {
      const orgId = clubId;
      fetchMembershipActions(orgId, currentIndividualId);
    }
  }, [clubId, currentIndividualId]);

  const {
    data: possibleSponsors,
    error: sponsorsError,
    loading: sponsorsLoading,
    fetchPossibleSponsors,
  } = useFetchPossibleSponsors();

  const payload = {
    clubId,
  };
  useEffect(() => {
    if (clubId) fetchPossibleSponsors(payload);
  }, [clubId]);

  const [selectedSponsor, setSelectedSponsors] = useState<
    ValueType<OptionTypeBase>
  >(null);

  const sponsoredBy = possibleSponsors?.availableSponsors
    ?.filter((sponsor: SponsorProp) => data?.sponsored?.includes(sponsor.id))
    .map(({ nameWithPrefixSuffix, id }: SponsorProp) => ({
      label: nameWithPrefixSuffix,
      value: id,
    }));

  useEffect(() => {
    setSelectedSponsors(sponsoredBy);
  }, [possibleSponsors]);

  useEffect(() => {
    disabled && setSelectedSponsors(null);
  }, [disabled]);

  const onChange = (options: ValueType<OptionTypeBase>) => {
    setisSubmitButtonDisabled?.(false);
    if (isUndefined(sponsoredBy)) {
      if (options?.length === 0) {
        setisSubmitButtonDisabled?.(true);
      }
    } else if (isEqual(sponsoredBy, options)) {
      setisSubmitButtonDisabled?.(true);
    }
    setSelectedSponsors(options);
    handleChange?.(options?.map((option: Option) => option?.value));
  };

  const options = possibleSponsors?.availableSponsors?.map(
    ({ nameWithPrefixSuffix, id }: SponsorProp) => ({
      label: nameWithPrefixSuffix,
      value: id,
    })
  );

  const formatGroupLabel = () => (
    <p className="normal-case text-gray-300 font-bold text-xs">
      {t('forms.select.fauxLabel', 'Filter by {{label}}', {
        selectLabel,
      })}
    </p>
  );
  const areTwoSponsorsSelected = selectedSponsor?.length >= 2;
  const isOptionDisabled = (option: OptionTypeBase) =>
    !selectedSponsor?.some(
      (sponsor: OptionTypeBase) => sponsor?.value === option?.value
    );
  const getNoOptionsMessage = () => {
    if (!options?.length && !name) {
      return t(
        'search.filters-sponsors-no-options',
        'Search for sponsors by typing'
      );
    }
    return t('search.select-no-options-default', 'No results');
  };

  return (
    <FormElement
      id={selectId}
      label={selectLabel}
      className={selectClassName}
      description={description}
      error={!!sponsorsError}
      errorMessage={sponsorsError?.message}
    >
      <ReactSelect
        id={`input-select-${selectId}`}
        className={classNames('Select text-xs')}
        styles={customStyles}
        options={options}
        noOptionsMessage={getNoOptionsMessage}
        isMulti
        isDisabled={disabled}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        name={selectName}
        isSearchable
        isClearable
        isOptionDisabled={option =>
          areTwoSponsorsSelected && isOptionDisabled(option)
        }
        value={selectedSponsor}
        onChange={onChange}
        isLoading={sponsorsLoading}
        onInputChange={setName}
        placeholder={selectPlaceholder}
        formatGroupLabel={formatGroupLabel}
        components={{
          Option: optionProps => {
            const { label } = optionProps;
            return (
              <OptionItem optionProps={optionProps}>
                <div className="text-gray-600">
                  {label.split('\n').map((str, index) => {
                    const key = `${str}-${index}`;
                    return <div key={key}>{str}</div>;
                  })}
                </div>
              </OptionItem>
            );
          },
        }}
      />
    </FormElement>
  );
};
